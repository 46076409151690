export const EXTERNAL_NAV_LINKS = [
  {
    // this one must remain at first position for the "Prendre Rendez-vous" button to work
    tooltip: "Prendre un Rendez-vous via Resalib",
    imgSrc: "../assets/img/resalib.png",
    altImg: "logo resalib",
    urlRef: "https://www.resalib.fr/praticien/68986-delphine-blot-therapeute-lille#newrdvmodal"
  },
  {
    tooltip: "Ma page Facebook",
    imgSrc: "../assets/img/fb.jpg",
    altImg: "logo faceBook",
    urlRef: "https://www.facebook.com/Blottherapie/"
  },
  {
    tooltip: "Mon contact sur les Pages Jaunes",
    imgSrc: "../assets/img/pj.jpg",
    altImg: "logo pages jaunes",
    urlRef: "https://www.pagesjaunes.fr/pros/55745321"
  },
  {
    tooltip: "Situez moi sur GoogleMap",
    imgSrc: "../assets/img/map.jpg",
    altImg: "logo google map",
    urlRef: "https://www.google.fr/maps/place/208+Rue+Jules+Guesde,+59160+Lille/@50.645701,2.9964025,17z/data=!3m1!4b1!4m5!3m4!1s0x47dcd5540c10beb9:0x42f4632532a92bfb!8m2!3d50.6456976!4d2.9985912"
  }
];