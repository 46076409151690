import { PageData } from "./page-data-model";

export const TF_DATA: PageData = {

    title: "Thérapie familiale",
    mainIllustration: "../assets/img/famille600x200.jpg",
    content: [
        {
            subTitle: "Déroulement de la thérapie familiale",
            text: "Je vous propose un accompagnement personnalisé pouvant aller du simple soutien à la parentalité à la thérapie familiale.<br /><br />" +
                "Je vous apporte alors une lecture différente du problème et vous permet d'opérer " +
                "vos propres changements en vous appuyant sur vos capacités individuelles et vos " +
                "propres ressources du système familial.<br /><br />" +
                "Mon rôle n'est pas de recréer une famille idéale mais d'évoluer ensemble vers un " +
                "fonctionnement plus souple, de dépasser une situation de crise, d'autoriser " +
                "l'évolution individuelle de chacun des membres et de trouver de nouveaux équilibres.<br /><br />" +
                "La thérapie familiale systémique se révèle être d’une grande bienveillance. Elle " +
                "est avant tout respectueuse de la singularité de chacun des membres de la famille.<br /><br />",
            list: [],
            photo: "../assets/img/illusFamille.jpg",
            xsPhoto: ""
        },
        {
            subTitle: "Principaux motifs de consultation familiale :",
            text: "",
            list: [
                {
                    line: "Difficultés ou absence de communication"
                },
                {
                    line: "Conflits à répétition"
                },
                {
                    line: "Équilibre bouleversé par une étape importante de la vie (adolescence difficile d’un enfant, mariage, naissance, accueil ou adoption d’un nouvel enfant etc.)"
                },
                {
                    line: "Situation familiale fragilisée par un deuil, une maladie, un handicap, une pathologie psychiatrique (dépression, bipolarité, etc.), des troubles du comportement d’un enfant," +
                        "des troubles du comportement alimentaire, des conduites à risque, une addiction (drogue, alcool etc.)"
                },
                {
                    line: "Violences verbales et/ou physiques"
                },
                {
                    line: "Incompréhensions"
                },
                {
                    line: "Besoin d’être accompagnés dans une étape de séparation, de divorce, de recomposition familiale"
                }
            ],
            photo: "../assets/img/illusFamille2.jpg",
            xsPhoto: ""
        }
    ]
}