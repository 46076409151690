import { PageData } from "./page-data-model";

export const TI_DATA: PageData = {

    title: "Thérapie Individuelle",
    mainIllustration: "../assets/img/alone600x200.jpg",
    content: [
        {
            subTitle: "Déroulement d'une thérapie individuelle",
            text: "La thérapie individuelle vise à vous soulager, à développer votre autonomie. <br /><br />" +
                "C’est un temps de soutien et d’écoute, un espace tourné vers le changement qui se fait par des entretiens réguliers.<br /><br />" +
                "Nous nous attacherons à identifier votre problème et son origine. Nous définirons " +
                "ensemble des objectifs communs et comment les atteindre grâce à un accompagnement adapté " +
                "à vos besoins, collaboratif et concret.<br /><br />" +
                "Nous instaurerons une nouvelle manière de penser, plus enveloppante envers vous-mêmes. " +
                "Des exercices concrets, des mises en situation, des métaphores rejouées en séances vous " +
                "permettront de lâcher prise sur ses pensées \"Hameçons\" qui vous empêchent d’agir.<br /><br />" +
                "<h3>Principaux motifs de consultation</h3>",
            list: [
                {
                    line: "angoisse"
                },
                {
                    line: "tristesse"
                },
                {
                    line: "rumination"
                },
                {
                    line: "stress professionnel, burnout"
                },
                {
                    line: "crise de panique"
                },
                {
                    line: "phobie"
                },
                {
                    line: "obsessions"
                },
                {
                    line: "perte de confiance en soi"
                },
                {
                    line: "isolement"
                },
                {
                    line: "inhibition"
                },
                {
                    line: "difficultés relationnelles"
                },
                {
                    line: "troubles alimentaires"
                }
            ],
            photo: "../assets/img/portrait2.jpg",
            xsPhoto: ""
        }
    ]
}