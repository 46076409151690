import { ScreenSizeService } from './screen-size.service';
import { Component, OnInit, HostListener, inject } from '@angular/core';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { slideInAnimationRight, slideInAnimationLeft } from './route-animation';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { EXTERNAL_NAV_LINKS } from './external-nav-links';
import { trigger, transition } from '@angular/animations';
import { routes } from 'src/routes';
import { NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatMenuModule, MatIconModule, RouterLink, MatTabsModule, RouterLinkActive, MatTooltipModule, RouterOutlet],
  animations: [
    trigger('animRoutes', [
      transition(':increment', slideInAnimationRight),
      transition(':decrement', slideInAnimationLeft),
    ]),
  ]
})

export class AppComponent implements OnInit {

  routes = routes.slice(1);
  externalNavLinks = EXTERNAL_NAV_LINKS;
  screenSizeService = inject(ScreenSizeService);
  isSmallScreen = this.screenSizeService.isSmallScreen;

  @HostListener('window:resize', ['$event'])
  ngOnInit(): void {
    this.screenSizeService.updateScreenSize();
  }

  openLink(link: string) {
    window.open(link, "_blank");
  }
}
