import { PageData } from "./page-data-model";

export const HYPNOSE_DATA: PageData = {

    title: "Hypnothérapie",
    mainIllustration: "../assets/img/write-600x200.jpg",
    content: [
        {
            subTitle: "Qu’est-ce que l'hypnose ?",
            text: "L'hypnose est un état naturel au cours duquel l'attention au monde extérieur est " +
                "diminuée pour permettre à l'inconscient d'être plus présent et de percevoir de nouvelles ressources.<br /><br />" +
                "C'est par la parole que le praticien induit l'hypnose chez son patient.<br /><br />" +
                "L'hypnose se définit par un état de conscience particulier, qui n'est ni un état de " +
                "sommeil, ni un état de veille.<br /><br />" +
                "L'hypnose est une thérapie composée de suggestions directes ou indirectes, de métaphores et de symboles.<br /><br />" +
                "Cette technique vous permet d'entrer dans un état de conscience modifiée, cet " +
                "état \"second\" vous offre la possibilité d'aller transformer votre façon de percevoir " +
                "votre difficulté et ainsi de la surmonter." +
                "<h3>Déroulement</h3>" +
                "La première séance (la prise de contact) est un échange verbal où se construit un lien de " +
                "collaboration afin de mieux connaitre la personne et de définir l'objectif à atteindre.<br /><br />" +
                "Les séances suivantes : je propose à la personne le choix d'un environnement qui permettra de " +
                "susciter l'état hypnotique, puis à l'aide de suggestions et d'images métaphoriques, je propose " +
                "progressivement au patient de commencer à modifier la façon dont il perçoit sa réalité. " +
                "Cela lui permet de vivre un autre possible pour quitter ses certitudes.<br /><br /> En fin de séance, " +
                "le patient reprend contact avec les éléments qui l'entourent (retour à l'état de conscience " +
                "ordinaire) et un échange est proposé." +
                "<h3>Principaux motifs de consultation d’hypnose :</h3>",
            list: [
                {
                    line: "Arrêt du tabac"
                },
                {
                    line: "Etat anxieux, stress, mal être"
                },
                {
                    line: "Séparations et deuils"
                },
                {
                    line: "Troubles du comportement alimentaire"
                },
                {
                    line: "Confiance en soi"
                },
                {
                    line: "Douleur"
                },
                {
                    line: "Phobies, troubles obsessionnels compulsifs"
                },
                {
                    line: "Troubles du sommeil"
                }
            ],
            photo: "../assets/img/hypillus.jpg",
            xsPhoto: ""
        }
    ]
}