<h1 class="h1">blot therapie lille</h1>

<div class="wrapper">
    <header class="header">
        <!------------------------------------------------------------- XS MENU ------------------------------------------------------------->
        <div class="xsmenu" [matMenuTriggerFor]="menu" #xsMenu="matMenuTrigger">
            <div class="linkMenu">
                <div id="nav-icon" [ngClass]="{'open': xsMenu.menuOpen}">
                    <!-- those spans are used by CSS to create the hamburger menu animation (toggle to cross) -->
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="hamburger-menu-text">Menu</div>
            </div>
            <mat-menu #menu="matMenu">
                @for (route of routes; track route) {
                <button mat-menu-item [routerLink]="route.path"
                    [routerLinkActive]="['activeXsMenuItem']">{{route.title}}
                </button>
                }
            </mat-menu>
            <ng-container *ngTemplateOutlet="extNavLink"></ng-container>
            <!---------------------------------------------------- EXTERNAL LINK TEMPLATE ------------------------------------------------------->
            <ng-template #extNavLink>
                <div class="logo">
                    @for (extLink of externalNavLinks; track extLink) {
                    <button class="external-link-button" (click)="openLink(extLink.urlRef)"
                        matTooltip={{extLink.tooltip}} matTooltipClass="tooltip"><img class="ext-link-img"
                            src={{extLink.imgSrc}} alt={{extLink.altImg}}></button>
                    }
                </div>
            </ng-template>
        </div>
        <!---------------------------------------------------------- STANDARD MENU ---------------------------------------------------------->
        <nav mat-tab-nav-bar [tabPanel]="tabPanel">
            <div class="link">
                @for (route of routes; track route) {
                <button mat-tab-link [routerLink]="route.path" routerLinkActive #rla="routerLinkActive"
                    [active]="rla.isActive"> {{route.title}}
                </button>
                }
            </div>
            <ng-container *ngTemplateOutlet="extNavLink"></ng-container>
        </nav>
        <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
        <!--------------------------------------------------------external Link Buttons------------------------------------------------------>

        <h1 class="title">BLOT THERAPIE</h1>
    </header>

    <div class="green-line"></div>

    <!------------------------------------------------------ router-outlet ------------------------------------------------------------------>
    <div class="content" [@animRoutes]="outlet.activatedRouteData['index']">
        <router-outlet #outlet="outlet">
            <div class="divRDV">
                <button mat-button class="buttonRDV" (click)="openLink(externalNavLinks[0].urlRef)"
                    matTooltip="Cliquez pour Prendre un Rendez-vous via Resalib" matTooltipClass="tooltip">Prendre
                    Rendez-vous</button>
            </div>
        </router-outlet>
    </div>

    <!------------------------------------------------------ FOOTER --------------------------------------------------------------------->
    <footer class="footer-container">
        <div class="green-line"></div>
        <div class="footer">
            <div>{{ isSmallScreen() ? '' : 'Adresse :'}} 208, rue Jules Guesde 59160 Lomme</div>
            <div>{{ isSmallScreen() ? '' : 'Telephone : '}} 06.95.12.10.19</div>
        </div>
    </footer>
</div>