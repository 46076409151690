import { PageData } from "./page-data-model";

export const TDC_DATA: PageData = {

    title: "Thérapie de couple",
    mainIllustration: "../assets/img/2couple600x200.jpg",
    content: [
        {
            subTitle: "Déroulement de la thérapie de couple",
            text: "Dans un couple, on passe par des hauts et des bas. <br /><br />" +
                "Mais que se passe-t-il quand on ne voit plus que les problèmes " +
                "que l’autre nous apporte, quand on sent qu’il ne s’agit plus d’une crise passagère ? Quand les deux " +
                "partenaires n’arrivent plus à sortir de cette spirale ?<br /><br />" +
                "La thérapie de couple permet d’introduire un tiers dans la relation. <br /><br />" +
                "Il ne s’agit pas de savoir qui a tort ou raison. L’objectif est de rétablir la " +
                "communication entre vous et d’assurer que votre relation soit à nouveau confortable et agréable.<br /><br />" +
                "La thérapie vous amène à écouter l’autre, à exprimer vos insatisfactions par rapport à la relation et non par " +
                "rapport à l’autre. Ce changement de perspective permet de revoir la relation sans viser à changer l’autre.<br /><br />",
            list: [],
            photo: "../assets/img/illusCouple.jpg",
            xsPhoto: ""
        },
        {
            subTitle: "Principaux motifs de consultation en couple :",
            text: "",
            list: [
                {
                    line: "Conflits à répétition"
                },
                {
                    line: "Sentiment d’impasse"
                },
                {
                    line: "Désaccords permanents"
                },
                {
                    line: "Jalousie excessive"
                },
                {
                    line: "Violences verbales et/ou physiques"
                },
                {
                    line: "Infidélité"
                },
                {
                    line: "Manque de confiance en l’autre"
                },
                {
                    line: "Incompréhensions"
                },
                {
                    line: "Difficultés ou absence de communication"
                },
                {
                    line: "Frustrations"
                },
                {
                    line: "Addictions diverses entraînant des problèmes conjugaux"
                },
                {
                    line: "Besoin d’être accompagnés dans une étape de séparation, de divorce ou de recomposition familiale"
                }
            ],
            photo: "../assets/img/illusCouple2.jpg",
            xsPhoto: ""
        }
    ]
}