import { Routes } from "@angular/router";
import { ACCUEIL_DATA } from "./app/pages-data-content/accueil-data";
import { HYPNOSE_DATA } from "./app/pages-data-content/hypnose-data";
import { TDC_DATA } from "./app/pages-data-content/therapie-de-couple-data";
import { TF_DATA } from "./app/pages-data-content/therapie-familiale-data";
import { TI_DATA } from "./app/pages-data-content/therapie-individuelle-data";

export const routes: Routes = [
    {
        path: '',
        redirectTo: '/accueil',
        pathMatch: 'full'
    },
    {
        path: 'accueil',
        loadComponent: () => import("./app/main-template/main-template.component").then(module => module.MainTemplateComponent),
        title: 'Accueil',
        data: { content: ACCUEIL_DATA, index: 1 }
    },
    {
        path: 'hypnose',
        loadComponent: () => import("./app/main-template/main-template.component").then(module => module.MainTemplateComponent),
        title: 'Hypnose',
        data: { content: HYPNOSE_DATA, index: 2 }
    },
    {
        path: 'therapie-individuelle',
        loadComponent: () => import("./app/main-template/main-template.component").then(module => module.MainTemplateComponent),
        title: 'Thérapie Individuelle',
        data: { content: TI_DATA, index: 3 }
    },
    {
        path: 'therapie-de-couple',
        loadComponent: () => import("./app/main-template/main-template.component").then(module => module.MainTemplateComponent),
        title: 'Thérapie de Couple',
        data: { content: TDC_DATA, index: 4 }
    },
    {
        path: 'therapie-familiale',
        loadComponent: () => import("./app/main-template/main-template.component").then(module => module.MainTemplateComponent),
        title: 'Thérapie Familiale',
        data: { content: TF_DATA, index: 5 }
    },
    {
        path: 'tarifs',
        loadComponent: () => import("./app/tarifs/tarifs.component").then(module => module.TarifsComponent),
        title: 'Tarifs',
        data: { index: 6 }
    },
    {
        path: 'infos-contact',
        loadComponent: () => import("./app/infos-contact/infos-contact.component").then(module => module.InfosContactComponent),
        title: 'Infos/Contact',
        data: { index: 7 }
    },
    {
        path: '**',
        redirectTo: '/accueil',
        pathMatch: 'full'
    }
];