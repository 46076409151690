import { Injectable, signal } from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class ScreenSizeService {

  isSmallScreen = signal<boolean>(false)

  updateScreenSize() {
    this.isSmallScreen.update(() => window.innerWidth <= 680 ? true : false)
  }
}