import { PageData } from "./page-data-model";

export const ACCUEIL_DATA: PageData = {
    
    title: "Delphine Blot",
    mainIllustration: "../assets/img/hand-check-600x200.jpg",
    content: [
        {
            subTitle: "Hypnothérapeute et thérapeute systémique",
            text: "Je suis thérapeute depuis 10 ans, je vous accompagne et vous aide à voir plus clair en vous, à réfléchir à ce" +
            "que vous êtes, ce que vous avez vécu, ce que vous vivez ou ce que vous ressentez.<br /><br />" +
            "Les séances vous permettent d'exprimer vos émotions, vos inquiétudes, vos difficultés, votre souffrance dans"+
            "un cadre bienveillant.<br /><br />" +
            "Je vous aide à défaire les nœuds pour mieux comprendre votre présent et avancer plus sereinement. Donner du"+
            "sens aux choses, aux événements ou à un comportement, modifier la perception du problème, mettre en place de"+
            "nouvelles expériences, amène un mieux-être.<br /><br />"+
            "Je vous accompagne en thérapie brève, cette thérapie est orientée sur le \"comment\" et les solutions plutôt"+
            "que sur le \"pourquoi\" et les causes (comme la psychanalyse). <br /><br />"+
            "Une séance dure environ une heure mais son rythme, sa durée et sa fréquence sont définis ensemble en fonction"+
            "de vos besoins.<br /><br /> Je vous propose des consultations :",
            list: [
                {
                    line: "en individuel (thérapie brève et/ou hypnose)",
                },
                {
                    line: "en couple (accompagnement dans les situations de conflits , de séparation , de recomposition familiale)",
                },
                {
                    line: "en famille (accompagnement du simple soutien à la parentalité à la thérapie familiale)"
                }
                
            ],
            photo: "../assets/img/portait.jpg",
            xsPhoto: ""
        },
        {
            subTitle: "Mes formations",
            text: "Instituts et écoles de formations : <br />" +
            "- Symbiofi Loos <br />" +
            "- Ecole d'hypnose et neurosciences appliquées Lille <br />" +
            "- Institut d'études de la Famille Lille <br />",
            list: [
                    {
                        line: "Thérapie d'acceptation et d'engagement"
                    },
                    {
                        line: "Psychologie positive"
                    },
                    {
                        line: "Médiation de pleine conscience"
                    },
                    {
                        line: "Ruminations et pensées répétitives négatives"
                    },
                    {
                        line: "Maître praticien en hypnose Ericksonienne"
                    },
                    {
                        line: "Formation à l'approche systémique et à la Thérapie Familiale"
                    }
            ],
            photo: "../assets/img/illus.jpg",
            xsPhoto: "../assets/img/note.jpg"
            },
            {
                subTitle: "Qu’est-ce que la thérapie systémique ?",
                text: "La thérapie systémique a été développée sous l'impulsion de l'anthropologue Gregory Bateson au " +
                "Mental Research Institute plus connu sous le nom de l'école de Palo Alto.<br /><br />"+
                "L'approche systémique se distingue des autres approches par sa façon de comprendre les relations "+
                "humaines: l'individu fait partie et est influencé par différents systèmes : "+
                "familial, professionnel, social…. Les personnes dépendent les unes des autres et leurs échanges se "+
                "font selon des règles implicites de communication utilisées le plus souvent "+
                "de manière inconsciente. Les difficultés d'une personne signalent parfois une souffrance qui peut "+
                "parfois être celle d'un système.<br /><br />"+
                "L'approche systémique prend donc en compte la communication et les interactions entre les individus. "+
                "Elle est particulièrement utilisée en thérapie familiale car une famille "+
                "constitue un bien sûr un système social naturel mais l'approche systémique peut bien sûr s'appliquer "+
                "dans le cadre d'une thérapie individuelle et non familiale. Modifier "+
                "unilatéralement ses relations avec les autres personnes du système peut avoir un effet sur le "+
                "fonctionnement du groupe.<br /><br />"+
                "En systémie, le thérapeute apporte une lecture différente des problèmes et permet d’opérer des "+
                "changements en s’appuyant sur les ressources du système qui l’entoure. "+
                "La systémie ne s’attache pas directement au symptôme mais tente d’identifier et de modifier le "+
                "contexte dans lequel il s’inscrit. La distinction entre psychanalyse et systémie "+
                "se retrouve de façon majeure dans la prise en compte du symptôme, pris ici comme un message, une «"+
                "entrée » ou « sortie » dans le système de communication de l’individu.<br />",
                list: [],
                photo: "../assets/img/zen.jpg",
                xsPhoto: "../assets/img/rdv.jpg"
                }
    ]
}
export { PageData };

